@import '../styles/colors.scss';

.toast-item {
    -webkit-box-shadow: 0px 2px 11px 0px rgba(0,0,0,0.42);
    -moz-box-shadow: 0px 2px 11px 0px rgba(0,0,0,0.42);
    box-shadow: 0px 2px 11px 0px rgba(0,0,0,0.42);
    width: 800px;
    border-radius: 8px;
    background: #fff;
    border: 0;
    box-sizing: border-box;
    padding: 18px;
    display: none;
    grid-template-columns: max-content 1fr;
    column-gap: 20px;
    cursor: pointer;
    grid-template-areas: "icon header" "icon message";

    &.show {
        display: grid;
        animation: fadeIn ease 1s;
    }

    .toast-icon {
        align-self: center;
        grid-area: icon;

        svg {
            width: 40px;
            height: 40px;
            vertical-align: middle;
        }
    }

    .toast-header {
        grid-area: header;
        font-size: 18px;
        font-weight: bold;
    }

    .toast-message {
        grid-area: message;
        color: #fff;
    }

    &.success {
        background: $success;

        .toast-header, .toast-icon {
            color: #fff;
            fill: #fff;
        }
    }

    &.warning {
        background: $warning;

        .toast-header, .toast-icon {
            color: #fff;
            fill: #fff;
        }
    }

    &.error {
        background: $error;

        .toast-header, .toast-icon {
            color: #fff;
            fill: #fff;
        }
    }
}

.toast-container {
    z-index: 10000;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column-reverse;
    padding: 48px;
    align-items: center;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
