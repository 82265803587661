@import '../styles/colors.scss';

.breeding-pair {
    padding: 20px;
    display: grid;
    grid-template-areas: 'asset1 separator asset2' 'path path path';
    justify-content: center;
    gap: 20px;


    .separator {
        width: 200px;
        grid-area: separator;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: $green;
            width: 30px;
        }
    }

    .asset1, .asset2 {
        justify-items: center;
        display: grid;
        grid-template-areas: 'dominant' 'image' 'counter' 'name';
        grid-template-rows: 30px 200px 40px 30px;
        flex-direction: column;
        width: 200px;
        gap: 8px;

        .footprint-dominant {
            grid-area: dominant;
            font-size: 0.8rem;
            line-height: 30px;

            svg {
                fill: $green;
                margin-right: 5px;
            }

            * {
                vertical-align: middle;
            }
        }

        img, .img-skeleton {
            width: 200px;
            height: 200px;
            grid-area: image;

            > span {
                width: 200px;
                height: 200px;
            }
        }

        .breeding-counter, .breeding-counter-skeleton {
            grid-area: counter;
        }

        .asset-name, .pick-button, .asset-name-skeleton {
            grid-area: name;
        }
    }

    .asset1 {
        grid-area: asset1;
    }

    .asset2 {
        grid-area: asset2;
    }

    .path {
        margin-left: 100px;
        margin-right: 100px;
        height: 100px;
        padding-top: 30px;
        grid-area: path;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 2px solid $green;
        border-bottom: 2px solid $green;
        border-right: 2px solid $green;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: 55px;
        box-sizing: border-box;

        .price {
            width: 150px;
            background: $background;
            padding: 10px;
            position: absolute;
            bottom: -55px;
            box-sizing: border-box;

            img {
                width: 50px;
            }
        }
    }
}

.breeding-actions {
    text-align: center;
}

.breeding-result {
    text-align: center;

    img {
        width: 300px;
    }
}

.breeding-counter {
    .value {
        font-family: 'Luckiest Guy', cursive;
        font-size: 1em;
        letter-spacing: 0.07em;
    }
}

.pick-button {
    border: 2px solid $primary;
    border-radius: 16px;
    padding: 2px;
    height: 24px;
    width: 100px;
    display: grid;
    grid-template-areas: 'icon text';
    grid-template-columns: max-content 1fr;
    align-items: center;
    cursor: pointer;

    .empty-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background: $primary;
    }

}

.clickable {
    cursor: pointer;
}

.asset-name {
    color: $green;
    border: 2px solid $green;
    border-radius: 16px;
    padding: 2px;
    height: 24px;
    width: 100px;
    display: grid;
    grid-template-areas: 'icon text';
    grid-template-columns: max-content 1fr;
    align-items: center;

    .cryptodino-icon, .dinosavior-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-size: cover;
        background-color: $primary;
    }

    .cryptodino-icon {
        background-image: url('../explore/cryptodino-icon.png');
    }

    .dinosavior-icon {
        background-image: url('../explore/dinosavior-icon.png');
    }
}

.progress {
    width: 400px;
    height: 400px;
}

.progress .fill {
    fill: rgba(0, 0, 0, 0);
    stroke-width: 2;
    transform: rotate(90deg)translate(0px, -80px);
    stroke: url('#progress-fill');
    stroke-dasharray: 219.99078369140625;
    // stroke-dashoffset: -219.99078369140625;
    transition: stroke-dashoffset 1s;
}

.breeding-status {

    border: 1px solid $pink;
    border-radius: 20px;
    width: 500px;
    padding: 10px;
    margin: 20px auto 0;
    box-sizing: border-box;

    h2 {
        font-family: 'Luckiest Guy', cursive;
        font-size: 2em;
        letter-spacing: 0.07em;
        margin-bottom: 5px;
    }

    h2 + div {
        margin-bottom: 10px;
    }

    img {
        width: 300px;
    }
}