.guide {
    max-width: 1100px;
    margin: 0 auto;

    h2 {
        color: #411946;
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.07em;
        position: relative;
        margin-top: 90px;
    }

    img {
        max-width: 1100px;
    }
}
