@import '../styles/colors.scss';

.panel {
    background: #191926;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 5px;

    .panel-section {
        padding: 10px;

        & + .panel-section {
            margin-top: 10px;
            border-top: 1px solid $background;
        }
    }
}

.summary-collectibles {
    display: flex;
    flex-direction: row;
    gap: 40px;

    div {

        display: inline-block;
        font-size: 16px;
        font-weight: bold;

        * {
            vertical-align: middle;
        }

        img {
            border-radius: 20px;
            height: 40px;
            margin-right: 20px;
        }
    }
}
