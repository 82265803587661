@import '../styles/colors.scss';

.account-tabs {
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
    padding-left: 0;

    li {
        list-style: none;



        a {
            border: 3px solid $primary;
            border-radius: 30px;
            border-bottom-right-radius: 0;
            display: block;
            padding: 10px 20px;
            text-decoration: none;
            font-weight: bold;

            &:hover {
                border-color: $primary-hover;
            }

            &.active, &.active:hover {
                padding-top: 15px;
                border-color: $active;
            }
        }
    }
}

.explore.account {
    article {
        padding: 10px 0;
    }
}