.story {
    width: 1100px;
    min-height: 100vh;
    margin: 0 auto;

    ul, ol {
        list-style-position: inside;
    }

    p:nth-child(2n+3) {
        position: relative;
        margin-top: 90px;

        &::before {
            content: url(../components/separator_footprint.svg);
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}
