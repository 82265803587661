.popup-menu {
    ul {
        flex-direction: column;

        li:before {
            display: none;
        }

        a:focus-visible {
            outline: none;
        }
    }
}

.popup-trigger:focus-visible {
    outline: none;
}