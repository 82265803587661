@import '../styles/colors.scss';

.switch {
    svg {
        width: 60px;
    }

    color: #fff;
}

.modal-dialog {
    .switch {
        color: $pink;
    }
}
