@import '../styles/colors.scss';

.leaderboard {
    table {
        margin: 0 auto;
        width: 800px;
        border-spacing: 0 5px;

        td {
            background: #191926;
            padding: 10px;
            color: #fff;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }

    .categories-tabs {
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: baseline;
        padding-left: 0;

        li {
            list-style: none;



            a {
                border: 3px solid $primary;
                border-radius: 30px;
                border-bottom-right-radius: 0;
                display: block;
                padding: 10px 20px;
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    border-color: $primary-hover;
                }

                &.active, &.active:hover {
                    padding-top: 15px;
                    border-color: $active;
                }
            }
        }
    }
}
