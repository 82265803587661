@import '../styles/colors.scss';

.modal-dialog {
    background: $gray;
    border-radius: 20px;
    max-width: 800px;
    -webkit-box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.16);
    box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.16);
    position: relative;

    * {
        color: #000;
    }

    button.close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: transparent;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 0;
        padding: 0;
        z-index: 1000;

        &:hover, &:active, &:focus {
            background: transparent;
        }

        svg {
            fill: $pink;
        }
    }

    &.cryptodinos h1 {
        background: url(BuyingBG_dinoPurple.png);
    }

    &.dinosaviors h1 {
        background: url(BuyingBG_stars.png);
    }

    h1 {
        margin: 0;
        color: $pink;
        font-family: 'Luckiest Guy', cursive;
        font-size: 1.5em;
        letter-spacing: 0.07em;
        padding: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        text-align: center;
    }

    .dialog-contnet {
        padding: 20px;
    }

    a {
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.04em;
        color: $pink;
    }

    p, button, h4 {
        color: $pink;
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.04em;
        text-align: center;
    }

    input, select {
        border: 0;
        border-radius: 20px;
        height: 40px;
        font-size: 1em;
        padding: 0 20px;
        margin-right: 16px;
        width: 200px;

        &::placeholder {
            color: #cac9cc;
        }

        &:focus {
            outline: none;
            transition: box-shadow ease-in 0.1s;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
            box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
        }

        &.error, &:invalid {
            background: rgba(#ff5f5f, 0.2);
            border: 2px solid #ff5f5f;
            transition: box-shadow ease-in 0.1s;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
            box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);

            &::placeholder {
                color: #ff5f5f;
            }
        }
    }

    button {
        border: none;
        background: $primary;
        border-radius: 20px;
        height: 40px;
        box-sizing: border-box;
        font-size: 1em;
        font-weight: bold;
        padding: 0 20px;
        color: $pink;
        cursor: pointer;

        * {
            vertical-align: middle;
        }

        &[disabled] {
            cursor: default;
        }

        &:hover:not([disabled]) {
            background: $primary-hover;
        }

        &:active:not([disabled]) {
            background: lighten($primary-active, 5%);
        }

        .spinner {
            animation: rotate 2s linear infinite;
            z-index: 2;
            width: 24px;
            height: 24px;
            margin-right: 8px;

            & .path {
                stroke: $pink;
                stroke-linecap: round;
                animation: dash 1.5s ease-in-out infinite;
            }
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

@media only screen and (max-device-width: 768px) {
    .modal-dialog {
        border-radius: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        h1 {
            border-radius: 0;
        }
    }
}