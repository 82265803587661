@import '../styles/colors.scss';

.step {
    text-align: left;
    margin-bottom: 20px;
    position: relative;

    &:before {
        border-radius: 1px;
        display: block;
        content: '';
        width: 2px;
        top: 27px;
        bottom: -17px;
        left: 11px;
        background: #404040;
        position: absolute;
    }

    .step-content {
        margin-left: 40px;

        p {
            font-size: 0.85rem;
        }
    }

    .warning {
        margin-top: 10px;
        background: $warning;
        border: 1px solid darken($warning, 10%);
        border-radius: 4px;
        padding: 8px;
        font-size: 0.85rem;
    }

    .step-header {
        display: grid;
        grid-template-areas: 'icon title';
        grid-template-columns: 40px auto;

        * {
            color: #404040;
        }

        .step-number {
            background-color: #404040;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            text-align: center;
            line-height: 24px;
            font-weight: bold;
            color: #000;
        }
    }

    &.done {
        &:before {
            background: $green;
        }

        .step-header {
            * {
                color: #fff;
            }
        }
    }

    &.active {
        .step-header {
            font-weight: bold;

            div {
                color: #fff;
            }
        }

        div.step-number {
            color: #000;
            background: $green;
        }
    }


    &:last-child {
        &:before {
            display: none;
        }
    }
}

.download {
    display: flex;
    gap: 40px;

    & > div {
        //text-align: center;
        width: 350px;
    }

    &.list > div {
        text-align: left;
        font-size: 0.85rem;

        ol {
            padding-left: 20px;
        }
    }
}

.check {
    fill: $green;
    width: 24px;
    height: 24px;
}

.sync-wallet {
    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        width: 32px;
        height: 32px;
        margin-right: 8px;

        & .path {
            stroke: $green;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }
}

.account-type-choice {
    display: flex;
    gap: 20px;
    & > div {
        border: 2px solid $primary;
        border-radius: 10px;
        width: 300px;
        padding: 20px;
        text-align: center;
    }
}