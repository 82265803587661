$gray: #eae8ef;
$pink: #411946;
$primary: #BC8EDD;
$primary-hover: #EED5FF;
$primary-active: #C36CFF;

$green: #CDDB56;
$purple-grey: #595B71;

$disabled-bg: rgb(197,195,201);
$disabled-fg: rgb(164,162,167);

$primary-btn-bg: #000;
$primary-btn-bg-hover: $green;
$primary-btn-bg-active: #fff;
$primary-btn-fg-active: #000;

$active: #EEFC65;

$background: #05050C;

$success: #51A351;
$warning: #f89406;
$error: #bd362f;