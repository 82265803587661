.panel-list {
    list-style: none;
}

.scholarship-entries {
    width: 600px;
    margin: 0 auto 10px;


    li {
        display: grid;
        grid-template-areas: 'image name ratio actions';
        grid-template-columns: 96px 2fr 1fr 1fr;
        gap: 8px;
        align-items: center;

        &.empty {
            display: block;
            text-align: center;
            padding: 30px;
        }

        .image {
            grid-area: image;

            img {
                width: 96px;
            }
        }

        .name {
            grid-area: name;
            text-align: left;
        }

        .ratio {
            grid-area: ratio;
            text-align: right;
        }

        .actions {
            grid-area: actions;
            display: flex;
        }
    }
}

.scholarship-dialog.dialog-content {
    padding: 10px;
    text-align: center;

    img {
        width: 200px;
    }

    .form-group {
        margin-bottom: 10px;

        input.numeric {
            text-align: right;
        }
    }

    h3 {
        margin: 10px 0 0;
    }
}
