ul.social-links {
    gap: 10px;
    margin-right: 10px;
    height: 28px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    list-style: none;

    a {
        padding: 4px 5px;
    }

    img {
        width: 20px;
        height: 20px;
        fill: #3F3F3F;
    }

    li::before {
        display: none;
    }
}
