.whitepaper {
    text-align: left;
    color: #411946;
    width: 1100px;
    margin: 0 auto;

    h2 {
        color: #fff;
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.07em;
        position: relative;
        margin-top: 90px;

        &::before {
            content: url(../components/separator_footprint.svg);
            position: absolute;
            top: -40px;
        }
    }

    h3 {
        color: #fff;
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.07em;

        & + p {
            margin-top: 0;
        }
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        width: 100%;

        th {
            background: #FFA4A4;
            color: #411946;
            font-family: 'Luckiest Guy', cursive;
            letter-spacing: 0.07em;
        }

        th, td {
            text-align: center;
            padding: 4px;
        }

        td {
            border-bottom: 1px solid #FFA4A4;
        }
    }

    ul {
        list-style: none; /* Remove default bullets */
        margin-bottom: 50px;
    }

    p {
        margin-top: 50px;
        margin-bottom: 20px
    }

    p + p {
        margin-top: 0;
    }

    ul li {
        position: relative;
    }

    ul li::before {
        position: absolute;
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #FFA4A4; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        font-size: 2em;
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        left: -20px;
        top: -9px;
    }

    img[alt=formula1] {
        margin: -20px 0 -30px 0px;
        height: 80px;
    }

    img[alt=formula2] {
        height: 120px;
        margin: -20px 0 -30px 0px;
    }

    img[alt=formula3] {
        height: 400px;
        margin: -20px 0 -30px 0px;
    }

    img[alt=activities] {
        width: 700px;
    }

    img[alt=performance] {
        width: 700px;
    }
}

@media only screen and (max-device-width: 768px) {
    .whitepaper {

        box-sizing: border-box;
        width: 100%;
        img {
            max-width: 90vw;
        }
    }
}