@import '../styles/colors.scss';

.ad-dialog {
    background: $gray;
    border-radius: 20px;
    max-width: 800px;
    -webkit-box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.16);
    box-shadow: 4px 4px 7px 0px rgba(0,0,0,0.16);
    position: relative;
    padding: 20px;

    * {
        color: #000;
    }

    button.close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: transparent;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 0;
        padding: 0;
        z-index: 1000;

        &:hover, &:active, &:focus {
            background: transparent;
        }

        svg {
            fill: $pink;
        }
    }
}