@import '../styles/colors.scss';

.sort-trigger {
    height: 36px;
    color: #fff;
    cursor: pointer;
    svg {
        fill: #fff;
    }
}

.sort-menu ul {
    z-index: 100;
    background: $primary;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    right: 0;

    li {
        padding: 5px;
        border-radius: 2px;

        a {
            color: $pink;
            text-decoration: none;
            outline: none;
        }
    }

    li:hover {
        background: $pink;

        a {
            color: $primary;
            outline: none;
        }
    }
}
