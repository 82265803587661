.rarity {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 20px;

    h2 {
        color: #fff;
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.07em;
        text-align: left;
    }

    .feature {
        padding: 0;
        margin-bottom: 10px;

        & > div {
            grid-gap: 0;
        }
    }

        & > div {
            width: 270px;
            margin-bottom: 40px;
        }
    }
