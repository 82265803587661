$primary: #FFA4A4;
$primary-hover: #ffbbbb;
$primary-active: #ff8888;
$pink: #411946;

.modal-dialog .buy {
    width: 800px;

    .full-width {
        padding: 0 20px;
    }

    & > h1, & > p, & > div {
        padding-left: 300px;
        padding-right: 20px;
    }

    .qr {
        text-align: center;
        img {
            width: 200px;
        }
        margin-bottom: 20px;
    }

    img.savior {
        margin-top: -50px;
        float: left;
        width: 240px;
    }

    .static-field {
        border: 0;
        border-radius: 20px;
        height: 40px;
        background: #fff;
        display: grid;
        grid-template-areas: 'value button';
        grid-template-columns: 1fr max-content;

        span:first-child {
            grid-area: value;
            align-self: center;
            margin: 10px 20px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        span + span {
            grid-area: button;
        }

        span + span button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .warning {
        margin-top: 20px;
        padding: 20px;
        background: url(../components/BuyingBG_stars.png);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        h4 {
            margin: 0 0 10px;
            font-size: 1.5em;
        }

        p {
            margin: 0;
        }
    }

    p.error {
        color: #FF5F5F;
    }
}
