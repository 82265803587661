.claim-reward-dialog {
    padding: 10px 20px;
    text-align: center;

    input {
        text-align: right;
    }

    button {
        margin-top: 10px;
    }
}