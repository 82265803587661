@import '../styles/colors.scss';

.tabs {
    max-width: 1100px;
    justify-content: center;
    margin: 0 auto 10px;
    display: grid;
    grid-template-areas: 'tabs extra';
    grid-template-columns: max-content auto;

    ul {
        border-bottom: 3px solid $primary;
        border-radius: 2px;
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        grid-area: tabs;
        gap: 38px;
    }

    > :last-child {
        grid-area: extra;
    }
}

.tabs li a {
    text-decoration: none;
    display: block;
    padding: 12px 10px;
}

.tabs li a.active-tab {
    font-weight: bold;
    border-radius: 2px;
    position: relative;
}

.tabs li a.active-tab::after {
    content: ' ';
    background: $active;
    height: 5px;
    border-radius: 3px;
    left: 0;
    right: 0;
    bottom: -4px;
    position: absolute;
}

.tabs + div {
    min-height: 150px;
}
