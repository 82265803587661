@import '../styles/colors.scss';

.login {

    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        width: 24px;
        height: 24px;
        margin-right: 8px;

        & .path {
            stroke: $pink;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }


    nav {
        display: grid;
        grid-template-areas: 'title filter extras';
        grid-template-columns: max-content 1fr max-content;
        padding-bottom: 20px;
        max-width: 1100px;
        margin: 0 auto;
        align-items: center;

        .title {
            text-align: left;
            grid-area: title;
            color: $pink;
            font-size: 1.2em;

            a {
                text-decoration: none;
                color: $pink;

                span {
                    vertical-align: middle;
                }
            }
        }
    }

    nav, article {
        input {
            border: 0;
            border-radius: 20px;
            height: 40px;
            font-size: 1em;
            padding: 0 20px;
            margin-right: 16px;

            &::placeholder {
                color: #cac9cc;
            }

            &:focus {
                outline: none;
                transition: box-shadow ease-in 0.1s;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
                box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
            }

            &.error {
                background: rgba(#ff5f5f, 0.2);
                border: 2px solid #ff5f5f;
                transition: box-shadow ease-in 0.1s;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
                box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
            }
        }

        .button {
            display: inline-block;
            text-decoration: none;
            line-height: 40px;
            vertical-align: middle;
        }

        button + .button, button + button, .button + button, .button + .button {
            margin-left: 10px;
        }

        button, .button {
            border: none;
            background: $primary;
            border-radius: 20px;
            height: 40px;
            box-sizing: border-box;
            font-size: 1em;
            font-weight: bold;
            padding: 0 20px;
            color: $pink;
            cursor: pointer;

            * {
                vertical-align: middle;
            }

            &:hover:not([disabled]) {
                background: $primary-hover;
            }

            &:active:not([disabled]) {
                background: lighten($primary-active, 5%);
            }

            &:focus-visible {
                outline: none;
            }

            svg {
                width: 24px;
                fill: $pink;
                margin-right: 8px;
            }

            &[disabled] {
                background: $disabled-bg;
                color: $disabled-fg;
                cursor: not-allowed;
                svg {
                    fill: $disabled-fg;
                }
            }
        }
    }

    article {
        padding: 20px;
    }

    h4 {
        font-family: 'Luckiest Guy', cursive;
        font-size: 1em;
        letter-spacing: 0.07em;
        color: $pink;
    }
}

.login-actions {
    text-align: center;
}