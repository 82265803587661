@import '../styles/colors.scss';

.enable-feature-dialog {
    text-align: center;
    padding: 10px 20px;

    p {
        font-family: 'Quicksand';
    }
}

.subscription-banner
{
    button {
        width: 100%;
        border-radius: 5px;
    }
    margin-bottom: 20px;
}

.featres-description {
    max-width: 400px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid $primary;
    margin-bottom: 20px;

    p {
        text-align: left;
        color: #000;
    }

    ul {
        padding-left: 20px;
    }

    li {
        font-size: 0.85rem;
        margin-bottom: 10px;
    }
}