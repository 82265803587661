.footer {
    background: #0B0D0D;
    display: grid;
    grid-template-areas: 'left center right' 'copyright copyright copyright';
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
    gap: 10px;

    & > svg {
        grid-area: center;
        justify-self: center;
        fill: #3F3F3F;
    }

    .social-links {
        grid-area: right;
        justify-self: right;
    }

    .copyright {
        padding-top: 10px;
        grid-area: copyright;
        justify-content: center;
        color: #3F3F3F;
        font-size: 0.75rem;

        a {
            color: inherit;
        }
    }
}

@media only screen and (max-device-width: 768px) {

    .footer {
        grid-template-columns: 1fr;
        grid-template-areas:
            'left' 'center' 'right' 'copyright';

        .social-links {
            justify-self: center;
        }
    }
}