.staking header img {
    height: 200px;
}

.staking-collection-header {
    display: grid;
    grid-template-areas: 'image name count daily profit';
    grid-template-columns: 100px 240px 60px 200px auto;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 20px 20px;
    align-items: center;

    .collection-name {
        grid-area: name;
        text-align: left;
    }

    .asset-count {
        grid-area: count;
        text-align: right;
    }

    .daily-profit {
        grid-area: daily;
        text-align: right;
        width: 200px;
    }

    .total-profit {
        grid-area: profit;
        text-align: right;
    }
}

.staking-collection {
    display: grid;
    grid-template-areas: 'image name count daily profit';
    grid-template-columns: 100px 240px 60px 200px auto;
    padding: 10px 20px;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    background: #191926;
    border-radius: 20px;
    margin-bottom: 5px;

    * {
        // color: #000;
    }

    .collection-image {
        grid-area: image;

        img {
            width: 78px;
            border-radius: 39px;
        }
    }

    .collection-name {
        grid-area: name;
        text-align: left;
    }

    .asset-count {
        grid-area: count;
        text-align: right;
    }

    .daily-profit {
        grid-area: daily;
        text-align: right;
    }

    .total-profit {
        grid-area: profit;
        font-weight: bold;
        color: #FFDF2E;
        text-align: right;
    }
}

.total-reward {
    color: #FFDF2E;
    font-weight: bold;
    font-size: 1.5rem;

    * {
        vertical-align: middle;
    }

    img {
        width: 36px;
    }
}
