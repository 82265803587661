@import '../styles/colors.scss';

.asset-gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;

    a {
        text-decoration: none;
        display: block;
        position: relative;
        background: #fff;
        border-radius: 20px;
        padding: 10px;
        text-align: left;

        img {
            width: 188px;
            height: 188px;
            background-image: url(loadingToken.png);
            background-size: cover;
            display: block;
            border-radius: 10px;
        }

        .faded {
            img {
                opacity: 0.5;
            }
        }

        p {
            font-size: 0.75rem;
            margin: 5px 0;
        }

        h3 {
            margin: 5px 0 0;
            span, img {
                vertical-align: middle;
            }

            span {
                color: #000;
            }

            span + img {
                margin-left: 10px;
            }
        }

        div {
            padding-top: 5px;
            font-weight: bold;
            letter-spacing: 0.07em;
            text-decoration: none;
            color: #000;
        }
    }
}

.infinite-scroll-component {
    overflow-x: hidden !important;
}

.asset-params {
    margin-bottom: 20px;
    text-align: left;
    padding: 0 10px;

    .dinocoins-amount {
        * {
            vertical-align: middle;
        }

        * + * {
            margin-left: 5px;
        }
    }
}

@media only screen and (max-device-width: 768px) {
    .asset-gallery-container {
        justify-content: center;
    }
}