@import '../styles/colors.scss';

.explore {

    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        width: 24px;
        height: 24px;
        margin-right: 8px;

        & .path {
            stroke: $pink;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }


    nav {
        display: grid;
        grid-template-areas: 'title filter extras';
        grid-template-columns: max-content 1fr max-content;
        padding-bottom: 20px;
        max-width: 1100px;
        margin: 0 auto;
        align-items: center;

        .title {
            text-align: left;
            grid-area: title;
            color: $pink;
            font-size: 1.2em;

            a {
                text-decoration: none;
                color: $pink;

                span {
                    vertical-align: middle;
                }

                .cryptodinos-icon {
                    background-image: url('cryptodino-icon.png');
                }

                .dinosaviors-icon {
                    background-image: url('dinosavior-icon.png');
                }

                .cryptodinos-icon, .dinosaviors-icon {
                    background-color: $primary;
                    width: 57px;
                    height: 57px;
                    border-radius: 28px;
                    display: inline-block;
                    margin-right: 10px;
                }

                &:hover {
                    .cryptodinos-icon, .dinosaviors-icon {
                        background-color: $primary-hover;
                    }
                }

                &:active {
                    .cryptodinos-icon, .dinosaviors-icon {
                        background-color: $primary-active;
                    }
                }
            }
        }

        .filters {
            text-align: center;
            grid-area: filter;
        }

        .extras {
            grid-area: extras;
            text-align: right;
            align-items: center;
            display: flex;
            gap: 20px;
        }
    }

    nav, article {
        input {
            border: 0;
            border-radius: 20px;
            height: 40px;
            font-size: 1em;
            padding: 0 20px;
            margin-right: 16px;
            color: #000;

            &::placeholder {
                color: #cac9cc;
            }

            &:focus {
                outline: none;
                transition: box-shadow ease-in 0.1s;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
                box-shadow: 0px 0px 5px 0px rgba(65, 25, 70, 1);
            }

            &.error {
                background: rgba(#ff5f5f, 0.2);
                border: 2px solid #ff5f5f;
                transition: box-shadow ease-in 0.1s;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
                box-shadow: 0px 0px 5px 0px rgba(#ff5f5f, 1);
            }
        }

        .button {
            display: inline-block;
            text-decoration: none;
            line-height: 40px;
            vertical-align: middle;
        }

        button + .button, button + button, .button + button, .button + .button {
            margin-left: 10px;
        }

        button, .button {
            border: 2px solid $green;
            background: #000;
            border-radius: 20px;
            height: 40px;
            box-sizing: border-box;
            font-size: 1em;
            font-weight: bold;
            padding: 0 20px;
            color: #fff;
            cursor: pointer;

            * {
                vertical-align: middle;
            }

            &:focus-visible {
                outline: none;
            }

            svg {
                width: 24px;
                fill: #fff;
                margin-right: 8px;
            }

            &[disabled] {
                background: $disabled-bg;
                color: $disabled-fg;
                cursor: not-allowed;

                svg {
                    fill: $disabled-fg;
                }
            }
        }
    }

    article {
        padding: 20px;
    }

    h4 {
        font-family: 'Luckiest Guy', cursive;
        font-size: 1em;
        letter-spacing: 0.07em;
        color: $pink;
    }
}

.feature {
    text-align: left;
    margin-bottom: 1px;
    font-size: 14px;
    color: $pink;
    padding: 2px 10px;

    & > div:first-child {
        display: grid;
        grid-template-areas: 'label value';
        grid-template-columns: max-content auto;
        grid-gap: 5px;
        margin-bottom: 5px;

        & > div:first-child {
            grid-area: label;
        }

        & > div:last-child {
            grid-area: value;
            font-weight: bold;
        }
    }
}

.uniqueness {
    font-size: 12px;
    color: rgba(122,123,157, 0.9);

    div:first-child {
        height: 5px;
        border-radius: 3px;
    }

    div.common {
        background: #F7B5B7;
    }

    div.uncommon {
        background: #D0939E;
    }

    div.rare {
        background: #95617B;
    }

    div.super-rare {
        background: #5D3258;
    }

    div.exceptional {
        background: #421A47;
    }
}

.asset {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    color: $pink;

    h3 {
        font-family: 'Luckiest Guy', cursive;
        font-size: 1em;
        letter-spacing: 0.07em;
        text-align: left;
        padding-left: 10px;
    }

    img {
        width: 600px;
        height: 600px;
    }

    & > div:nth-child(2) {
        width: 400px;
    }

    .address {
        border: 2px solid $pink;
        color: $pink;
        border-radius: 25px;
        padding: 8px 20px;
        margin-bottom: 10px;
        display: inline-block;
        max-width: 600px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price-tag {
        color: $pink;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .asset {
        flex-direction: column;

        img {
            width: auto;
            height: auto;
        }

        .feature {
            padding: 2px 0;
        }
    }

    .explore {

        nav {
            padding: 0 20px;
            grid-template-areas: 'title' 'filter';
            gap: 8px;

            div:nth-child(2) {
                display: flex;
                flex-direction: row;

                button {
                    white-space: nowrap;
                }
            }
        }
    }
}

.no-items {
    text-align: center;

    a {
        font-family: inherit;
    }
}

.dc-claim-actions {
    text-align: center;
}

.tabs li a {
    span {
        vertical-align: middle;
    }

    .cryptodinos span:first-child {
        background-image: url('cryptodino-icon.png');
    }

    .dinosaviors span:first-child {
        background-image: url('dinosavior-icon.png');
    }

    .hatching-room span:first-child {
        background-image: url('egg-icon.png');
    }

    .cryptodinos, .dinosaviors, .hatching-room {
        span:first-child {
            background-color: $primary;
            background-size: contain;
            width: 48px;
            height: 48px;
            border-radius: 24px;
            display: inline-block;
            margin-right: 10px;
        }
    }

    &:hover {
        .cryptodinos, .dinosaviors, .hatching-room {
            span:first-child {
                background-color: $primary-hover;
            }
        }
    }

    &:active {
        .cryptodinos, .dinosaviors, .hatching-room {
            span:first-child {
                background-color: $primary-active;
            }
        }
    }

    &.active-tab {
        .cryptodinos, .dinosaviors, .hatching-room {
            span:first-child {
                background-color: $active;
            }
        }
    }
}

.account-status {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: right;
    padding: 12px 0;
    gap: 12px;

    span {
        font-family: 'Luckiest Guy', cursive;
        font-size: 2em;
        letter-spacing: 0.07em;
        color: #fff;
        vertical-align: middle;
        margin-right: 10px;
    }

    img {
        width: 48px;
        height: 48px;
        vertical-align: middle;
    }
}

@media only screen and (max-device-width: 768px) {
    .asset {
        flex-direction: column;

        img {
            width: 100%;
            height: auto;
        }

        .feature {
            padding: 2px 0;
        }
    }

    .explore {

        nav {
            padding: 0 20px;
            grid-template-areas: 'title' 'filter' 'extras';
            gap: 8px;

            div:nth-child(2) {
                display: flex;
                flex-direction: row;

                button, a {
                    white-space: nowrap;
                    width: 40px;
                    height: 40px;
                    padding: 8px;
                    span {
                        display: none;
                    }

                    svg {
                        margin-top: -20px;
                    }
                }
            }
        }
    }
}

.game-level {
    margin-top: 10px;
}