@import '../styles/colors.scss';

.project-accounts-list {
    width: 1000px;
    margin: 0 auto;
    text-align: left;

    h2 {
        margin-bottom: 0;
    }

    p {
        margin: 0;
    }

    pre {
        border: 2px solid lighten(#191926, 10%);
        border-radius: 5px;
        padding: 5px;
        font-family: 'Consolas';
        background: lighten(#191926, 20%);
    }
}