@import '../styles/colors.scss';

.header {
    height: 100px;
    box-sizing: border-box;
    background: #000;
    color: #fff;
    display: grid;
    grid-template-areas: 'logo menu';
    grid-template-columns: 1fr 2fr;
    align-items: center;

    .logo {
        grid-area: logo;
        position: relative;

        img {
            height: 150px;
            top: -50px;
            position: absolute;
            z-index: 1;
        }
    }

    .menu-button {
        display: none;
        grid-area: menu;
        width: 24px;
        height: 24px;
        background: none;
        border: none;
    }

    .menu {
        grid-area: menu;
        display: grid;
        grid-template-areas: 'menu account';
        grid-template-columns: 1fr 1fr;
        font-size: 1.25rem;

        button {
            font-size: inherit;
            margin-left: 20px;
        }

        ul {
            grid-area: menu;
            display: flex;
            flex-direction: row;
            list-style: none;
            padding-left: 0;
            justify-content: center;

            li {
                padding: 0 20px;
            }

            a, a:visited {
                font-weight: bold;
                color: #fff;
                text-decoration: none;
            }
        }

        .account-menu {
            grid-area: account;
            align-self: center;

            a, button {
                display: inline-block;
                border: 3px solid white;
                border-radius: 15px;
                padding: 10px;
                color: #fff;
                text-decoration: none;
                width: 200px;
                background: transparent;
                font-weight: bold;

                &.primary {
                    border-color: $primary;
                }

                & + a, button {
                    margin-left: 10px;
                }

                &.highlight {
                    background: #CDDB56;
                    border-color: #CDDB56;
                    color: #000;
                    font-weight: bold;
                }
            }
        }
    }
}

@media only screen and (max-device-width: 768px) {
    .header {
        grid-template-areas: 'logo menu';
        grid-template-columns: 1fr max-content;
        padding: 0 20px;


        .menu-button {
            display: inline-block;
        }

        .menu {
            display: none;

            &.show {
                display: grid;
            }

            grid-template-areas: 
                'menu'
                'account';
            position: absolute;
            z-index: 2;
            top: 75px;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.9);
            padding: 20px;

            ul {
                flex-direction: column;

                li {
                    padding: 10px;
                }
            }
        }
    }
}

.connect-dialog > div {
    padding: 10px 20px;

    .wallet-item {
        & + .wallet-item {
            margin-top: 5px;
        }

        width: 300px;
        display: grid;
        grid-template-areas: 'logo name connect';
        grid-template-columns: 32px auto 100px;
        gap: 10px;
        align-items: center;

        img {
            grid-area: logo;
        }

        span {
            grid-area: name;
        }

        button {
            grid-area: connect;
        }
    }
}