@import '../styles/colors.scss';

.asset-dialog {
    width: 800px;

    .switch-collection {
        text-decoration: none;
        color: $pink;
        cursor: pointer;

        span {
            vertical-align: middle;
        }

        .cryptodinos-icon {
            background-image: url('cryptodino-icon.png');
        }

        .dinosaviors-icon {
            background-image: url('dinosavior-icon.png');
        }

        .cryptodinos-icon, .dinosaviors-icon {
            background-color: $primary;
            width: 57px;
            height: 57px;
            border-radius: 28px;
            display: inline-block;
            margin-right: 10px;
        }

        &:hover {
            .cryptodinos-icon, .dinosaviors-icon {
                background-color: $primary-hover;
            }
        }

        &:active {
            .cryptodinos-icon, .dinosaviors-icon {
                background-color: $primary-active;
            }
        }
    }

    nav {
        padding: 10px;
        display: grid;
        grid-template-areas: 'collection filters';
        grid-template-columns: 1fr max-content;

        div:first-child {
            grid-area: collection;
        }

        div:last-child {
            grid-area: filters;
            align-items: center;
            display: flex;

            input {
                width: 120px;
            }
        }
    }

    .gallery {
        height: 500px;
        overflow-y: scroll;

        .asset-gallery-container {
            padding: 0 20px 20px;

            & > div {
                width: 190px;
                height: 250px;

                img {
                    width: 180px;
                    height: 180px;
                }

                .img-skeleton {
                    width: 180px;
                    height: 180px;
                }

                .bottom-badge {
                    width: 180px;
                }

                div {
                    padding-top: 5px;
                }

                .name {
                    color: $pink;
                }
            }

            text-align: center;
        }

        &::-webkit-scrollbar {
            width: 7px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $pink;
            border-radius: 4px;
            margin-right: 10px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: darken($pink, 10%);
        }
    }
}
