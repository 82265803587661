@import '../styles/colors.scss';

.page {
    background: $background;
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 50px;

    * {
        color: #fff;
    }

    & > div:first-child {
        padding-bottom: 80px;
    }

    .logo {

        img {
            top: 30px;
            left: 50px;
            position: absolute;
        }
    }

    .hills {
        width: 100%;
        background: #fff;
        fill: $gray;
    }

    h1 {
        margin: 0 auto 10px;
        font-family: 'Luckiest Guy', cursive;
        font-size: 3em;
        letter-spacing: 0.07em;
        color: #fff;

        big {
            font-family: 'Luckiest Guy', cursive;
            font-size: 1.25em;
            color: inherit;
        }
    }

    header {
        padding-bottom: 30px;
    }

    .scroll-up {
        position: sticky;
        bottom: 10px;
        margin-left: calc(100% - 100px);
        margin-top: -96px;
        text-align: right;
        padding-right: 10px;

        button {
            width: 56px;
            height: 56px;
            border: none;
            background: $primary;
            border-radius: 28px;
            padding: 0;

            svg {
                cursor: pointer;
                fill: $pink;
            }

            &:hover {
                background: $primary-hover;
            }

            &:active {
                background: lighten($primary-active, 5%);
            }
        }
    }
}

@media only screen and (max-device-width: 768px) {
    .page {
        padding: 10px;
        width: 100vw;

        h1 {
            font-size: 2em;
        }
    }
}