@import '../styles/colors.scss';

.button + .button {
    margin-left: 10px;
}

.button {
    display: inline-block;
    text-decoration: none;
    line-height: 40px;
    vertical-align: middle;
    border: none;
    background: $primary;
    border-radius: 20px;
    height: 40px;
    box-sizing: border-box;
    font-size: 1em;
    font-weight: bold;
    padding: 0 20px;
    color: $pink;
    cursor: pointer;

    * {
        vertical-align: middle;
    }

    &:hover:not([disabled]) {
        background: $primary-btn-bg-hover;
    }

    &:active:not([disabled]) {
        background: $primary-btn-bg-active;
        color: $primary-btn-fg-active;
        span {
            color: inherit;
        }
        svg {
            fill: $primary-btn-fg-active;
        }
    }

    &:focus-visible {
        outline: none;
    }

    svg {
        width: 24px;
        fill: $pink;
        margin-right: 8px;
    }

    &[disabled] {
        background: $disabled-bg;
        color: $disabled-fg;
        cursor: not-allowed;

        svg {
            fill: $disabled-fg;
        }
    }
}
