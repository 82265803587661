$pink: #411946;


.modal-dialog {
    &.cryptodinos h1 img {
        width: 300px;
        top: -25px;
    }

    &.dinosaviors h1 img {
        top: -80px;
    }

    h1 {
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
        }
    }
}

.filter {
    width: 800px;

    .fields {
        padding: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 10px;

        div {
            display: flex;
            align-items: center;
            gap: 10px;

            label {
                width: 150px;
                color: $pink;
                font-family: 'Luckiest Guy', cursive;
                letter-spacing: 0.07em;
                text-align: right;
            }

            select {
                width: 200px;
            }
        }
    }

    .actions {
        padding: 20px;
        text-align: center;
    }
}

@media only screen and (max-device-width: 768px) {
    .filter {
        width: 100%;
    }
    .filter .fields {
        flex-direction: column;
    }
}