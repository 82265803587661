.banner {
    position: relative;
    background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 10%, rgba(0,0,0,0.3) 80%, rgba(0,0,0,1) 100%);

    img {
        width: 100%;
        position: relative;
        z-index: -1;
        display: block;
    }

    .banner-content {
        position: absolute;
        left: 5%;
        bottom: 10%;
        padding: 20px;

        h1 {
            font-family: 'Luckiest Guy', cursive;
            letter-spacing: 0.07em;
            text-align: left;
            font-size: 4em;
            margin: 0;
        }

        h3 {
            text-align: left;
        }

        a {
            display: inline-block;
            border: 1px solid white;
            border-radius: 2px;
            padding: 10px;
            color: #fff;
            text-decoration: none;
            width: 150px;

            & + a {
                margin-left: 10px;
            }
        }
    }
}

@media only screen and (max-device-width: 768px) {
    .banner img {
        height: 35vh;
        object-fit:cover;
    }

    .banner .banner-content {
        position: initial;

        h1 {
            font-size: 3em;
        }

        h1, h3 {
            text-align: center;
        }

        a {
            width: 100px;
        }
    }
}

.home-section {

    &:first-child {
        padding-top: 0;
    }

    img {
        max-width: 50%;
    }

    h1 {
        font-family: 'Luckiest Guy', cursive;
        letter-spacing: 0.07em;
        font-size: 3em;
        margin: 0;
    }

    p {
        font-size: 1.2em;
    }

    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
}
