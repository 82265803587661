@import '../styles/colors.scss';

.marketplace {
    width: 600px;

    h1 {
        padding-left: 200px;
    }

    & > div {
        text-align: center;
        padding: 10px;
    }

    span {
        color: $pink;
    }

    input {
        text-align: right;
        margin-left: 10px;
    }

    button {
        margin-bottom: 10px;
    }
}